import ScrollMagic from 'scrollmagic';

export function scrollmagic() {
  let controller = new ScrollMagic.Controller();

  /** view */
  let fadeIn = document.querySelectorAll('.js-scrollView');
  for (let i = 0; i < fadeIn.length; i++) {
    let tag = fadeIn[i];

    new ScrollMagic.Scene({
      triggerElement: tag,
      triggerHook: 'onEnter',
      offset: 200,
      reverse: false,
    })
      .setClassToggle(tag, 'is-view')
      .addTo(controller);
  }
  /** end view */

  /** view */
  let fadeInEnter = document.querySelectorAll('.js-scrollEnterView');
  for (let i = 0; i < fadeInEnter.length; i++) {
    let tag = fadeInEnter[i];

    new ScrollMagic.Scene({
      triggerElement: tag,
      triggerHook: 'onLeave',
      offset: 0,
      reverse: true,
    })
      .setClassToggle(tag, 'is-view')
      .addTo(controller);
  }
  /** end view */
}
