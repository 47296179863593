export function gallery() {
  const galleryItem = document.querySelectorAll('.js-galleryItem');
  const galleryItemList = Array.prototype.slice.call(galleryItem, 0);

  const bodyEle = document.querySelector('body');
  const galleryOverlayEle = document.querySelector('.js-galleryOverlay');
  const galleryModalEle = document.querySelector('.js-galleryModal');

  const galleryId = document.getElementById('galleryModal');

  let gallerList = [];

  const galleryNavLeftEle = document.querySelector('.js-galleryNavLeft');
  const galleryNavRightEle = document.querySelector('.js-galleryNavRight');

  galleryItemList.forEach((targetBox, index) => {
    targetBox.addEventListener('click', () => {
      /** モーダル用情報の取得 */
      const gallerySelectedNumber = Number(index);
      let galleryPagerAll = Number(galleryItemList.length); // モーダル総数

      if (galleryPagerAll < 10) {
        galleryPagerAll = '0' + galleryPagerAll;
      }

      /** 情報反映 */
      for (let i = 0; i < galleryItemList.length; i++) {
        const galleryImgData = galleryItemList[i].dataset.img; // 画像ファイル名
        const galleryPlaceData = galleryItemList[i].dataset.place; // 場所

        let galleryPagerCurent = 0; // 選択したモーダル番号用

        if (i < 10) {
          galleryPagerCurent = '0' + Number(i + 1);
        } else {
          galleryPagerCurent = Number(i + 1);
        }

        if (gallerySelectedNumber == i) {
          gallerList += `
          <li class="c-galleryModal_list js-galleryModalItem is-view">
            <div class="c-galleryModal_pic">
              <div class="c-galleryModal_close js-galleryClose"></div>
              <div class="c-galleryModal_picInner">
                <img src="/wp-content/themes/rinc/assets/img/${galleryPlaceData}/guide/${galleryImgData}.png" alt="">
              </div>
            </div>
          </li>
          `;
        } else {
          gallerList += `
          <li class="c-galleryModal_list js-galleryModalItem">
            <div class="c-galleryModal_pic">
              <div class="c-galleryModal_close js-galleryClose"></div>
              <div class="c-galleryModal_picInner">
                <img src="/wp-content/themes/rinc/assets/img/${galleryPlaceData}/guide/${galleryImgData}.png" alt="">
              </div>
            </div>
          </li>`;
        }
      }

      const gallerCont = document.createElement('ul');
      gallerCont.className = 'c-galleryModal_cont';

      gallerCont.innerHTML = gallerList;

      galleryId.appendChild(gallerCont);

      /** モーダル表示・非表示制御 */
      bodyEle.classList.add('is-fixed');
      galleryOverlayEle.classList.add('is-view');
      galleryModalEle.classList.add('is-view');
      gallerList = [];

      /** モーダルクローズボタン処理 */
      const galleryClose = gallerCont.querySelectorAll('.js-galleryClose');
      const galleryCloseList = Array.prototype.slice.call(galleryClose, 0);

      galleryCloseList.forEach((targetBox, index) => {
        targetBox.addEventListener('click', () => {
          document.querySelector('.c-galleryModal_cont').remove();
          bodyEle.classList.remove('is-fixed');
          galleryOverlayEle.classList.remove('is-view');
          galleryModalEle.classList.remove('is-view');
          gallerList = [];
        });
      });
    });
  });

  /** モーダル背景のオーバーレイ選択時でも閉じる */
  if (document.querySelector('.js-galleryOverlay')) {
    galleryOverlayEle.addEventListener('click', () => {
      document.querySelector('.c-galleryModal_cont').remove();
      bodyEle.classList.remove('is-fixed');
      galleryOverlayEle.classList.remove('is-view');
      galleryModalEle.classList.remove('is-view');
    });
  }

  /** モーダル切り替え */
  if (document.querySelector('.js-galleryNavLeft')) {
    galleryNavLeftEle.addEventListener('click', () => {
      const galleryModalItem = document.querySelectorAll('.js-galleryModalItem');
      const galleryModalItemList = Array.prototype.slice.call(galleryModalItem, 0);
      let targetGallery;

      galleryModalItemList.forEach((targetBox, index) => {
        if (targetBox.classList.contains('is-view')) {
          targetBox.classList.remove('is-view'); // 現在表示されている内容を非表示にする
          targetGallery = targetBox;
        }
      });

      // 現在表示されている内容の前に要素があるか判別
      if (targetGallery.previousElementSibling) {
        targetGallery.previousElementSibling.classList.add('is-view');
      } else {
        document.querySelector('.c-galleryModal_cont').lastElementChild.classList.add('is-view');
      }
    });
  }

  if (document.querySelector('.js-galleryNavRight')) {
    galleryNavRightEle.addEventListener('click', () => {
      const galleryModalItem = document.querySelectorAll('.js-galleryModalItem');
      const galleryModalItemList = Array.prototype.slice.call(galleryModalItem, 0);
      let targetGallery;

      galleryModalItemList.forEach((targetBox, index) => {
        if (targetBox.classList.contains('is-view')) {
          targetBox.classList.remove('is-view'); // 現在表示されている内容を非表示にする
          targetGallery = targetBox;
        }
      });

      // 現在表示されている内容の前に要素があるか判別
      if (targetGallery.nextElementSibling) {
        targetGallery.nextElementSibling.classList.add('is-view');
      } else {
        document.querySelector('.c-galleryModal_cont').firstElementChild.classList.add('is-view');
      }
    });
  }
}
