import smoothscroll from 'smoothscroll-polyfill';
import SmoothScroll from 'smooth-scroll';

export function smooth() {
  smoothscroll.polyfill();

  window.addEventListener('load', () => {
    const scroll = new SmoothScroll('a[href*="#"]', {
      header: '#header',
      speed: 300,
      speedAsDuration: true,
      updateURL: false,
    });
  });
}
