import { accordion } from './modules/accordion';
import { gallery } from './modules/gallery';
import { toggleHamburger } from './modules/hamburger';
import { rellaxAction } from './modules/rellax';
import { scrollHint } from './modules/scrollHint';
import { scrollmagic } from './modules/scrollMagic';
import { smooth } from './modules/smooth';

accordion();
gallery();
toggleHamburger();
rellaxAction();
scrollHint();
scrollmagic();
smooth();
