import Rellax from 'rellax';

export function rellaxAction() {
  // New
  const rellax = new Rellax('.js-rellax', {
    speed: -1,
    center: true,
    wrapper: null,
    round: true,
    vertical: true,
    horizontal: false,
  });
}
