export function accordion() {
  const jsAccordionButtons = document.querySelectorAll('.js-accordion-button');
  const jsAccordionButtonList = Array.prototype.slice.call(jsAccordionButtons, 0);

  jsAccordionButtonList.forEach((element) => {
    element.addEventListener('click', () => {
      const jsAccordionContent = element.nextElementSibling;
      element.classList.toggle('is-active');
      jsAccordionContent.classList.toggle('is-active');
    });
  });
}
